/* eslint-disable react/react-in-jsx-scope */
import {Dashboard} from '../pages/Dashboard'
import {
  ProjectsList,
  ProjectsDetail,
  ProjectsEdit,
  ProjectsCreate,
  EstateList,
  EstateDetail,
  EstateEdit,
  EstateCreate,
  ContractsList,
  ContractsDetail,
  ContractsEdit,
  ContractsCreate,
  BlockList,
  BlockDetail,
  BlockEdit,
  BlockCreate,
  BuildingBlockTypeList,
  BuildingBlockTypeDetail,
  BuildingBlockTypeEdit,
  BuildingBlockTypeCreate,
  ConstructionSiteList,
  ConstructionSiteDetail,
  ConstructionSiteEdit,
  ConstructionSiteCreate,
  PlotList,
  PlotDetail,
  PlotEdit,
  PlotCreate,
} from '../pages/Projects'
import {
  ExpensesList,
  ExpensesBankDetail,
  ExpensesBankEdit,
  ExpensesBankCreate,
  ExpensesDetailDetail,
  ExpensesDetailEdit,
  ExpensesDetailCreate,
  ExpensesFastDetail,
  ExpensesFastEdit,
  ExpensesFastCreate,
  ExpensesSundryDetail,
  ExpensesSundryEdit,
  ExpensesSundryCreate,
  ExpensesTaxDetail,
  ExpensesTaxEdit,
  ExpensesTaxCreate,
  SubcontractorList,
  SubcontractorDetail,
  SubcontractorEdit,
  SubcontractorCreate,
  SupplierList,
  SupplierDetail,
  SupplierEdit,
  SupplierCreate,
  PaymentRequestList,
  PaymentRequestDetail,
  PaymentRequestEdit,
  PaymentRequestCreate,
  WaitingConfirmationList,
  //GibInvoicesCreate,
  //GibInvoicesList
} from '../pages/Expenses'
import {
  InvoicesList,
  InvoicesDetail,
  InvoicesEdit,
  InvoicesCreate,
  CustomersList,
  CustomersDetail,
  CustomersEdit,
  CustomersCreate,
  ProposalCreate,
  ProposalDetail,
  ProposalEdit,
  ProposalList,
} from '../pages/Sales'
import {
  BankList,
  BankDetail,
  BankEdit,
  BankCreate,
  ChecksList,
  ChecksDetail,
  ChecksEdit,
  ChecksCreate,
  ExpenseList,
  ExpenseDetail,
  ExpenseCreate,
  ExpenseEdit,
  SaleList,
  SaleDetail,
  SaleCreate,
  SaleEdit,
  GuaranteeletterList,
  GuaranteeletterDetail,
  GuaranteeletterCreate,
  GuaranteeletterEdit,
  TransactionsList,
  UnscheduledList,
  CheckoutApproveList,
  BankPaymentOrderList,
  BankPaymentOrderDetail,
  BankPaymentOrderCreate,
  BankPaymentOrderEdit,
} from '../pages/Cash'
import {
  LeaveList,
  LeaveDetail,
  LeaveEdit,
  LeaveCreate,
  SalaryList,
  SalaryDetail,
  SalaryEdit,
  SalaryCreate,
  OvertimeList,
  OvertimeDetail,
  OvertimeEdit,
  OvertimeCreate,
  StaffList,
  StaffDetail,
  StaffEdit,
  StaffCreate,
  MonthlySalaryList,
  MonthlySalaryDetail,
  MonthlySalaryEdit,
  MonthlySalaryCreate,
} from '../pages/Staff'
import {
  InWaybillsList,
  InWaybillsDetail,
  InWaybillsEdit,
  InWaybillsCreate,
  OutWaybillsList,
  OutWaybillsDetail,
  OutWaybillsEdit,
  OutWaybillsCreate,
  ProductList,
  ProductDetail,
  ProductEdit,
  ProductCreate,
  WarehouseList,
  WarehouseDetail,
  WarehouseEdit,
  WarehouseCreate,
  TransferList,
  TransferDetail,
  TransferEdit,
  TransferCreate,
} from '../pages/Stock'
import {
  CategoriesList,
  CompanyList,
  CompanyDetail,
  CompanyEdit,
  UsersList,
  UsersDetail,
  UsersEdit,
  UsersCreate,
} from '../pages/Admin'
import {
  ActivityReportCreate,
  ActivityReportDetail,
  ActivityReportList,
  ActivityReportEdit,
  TaskCreate,
  TaskDetail,
  TaskEdit,
  TaskList,
} from '../pages/Workplan'
import {
  OrderCreate,
  OrderDetail,
  OrderEdit,
  OrderList,
  RequestCreate,
  RequestDetail,
  RequestEdit,
  RequestList,
} from '../pages/Purchase'
import {
  DynamicReportList,
  DynamicReportExpenseInvoicePaymentPlan,
  DynamicReportSaleInvoicePaymentPlan,
  DynamicReportExpenseWithBalance,
  DynamicReportSaleWithBalance,
  DynamicReportChecksWithExpenseInvoice,
  DynamicReportTaskCompleteRateByProject,
  DynamicReportTaskCompleteRateByConstructionSite,
  DynamicReportTaskAndPlotCompleteRateByConstructionSite,
  WorkProgramReport,
  SaleInvoiceReport,
  ExpenseInvoiceReport,
  DynamicReportMonthlyPayroll,
  AuditList,
  CashFlowReport,
} from '../pages/Reporting'
import {
  UserTaskCreate,
  UserTaskDetail,
  UserTaskEdit,
  UserTaskList,
} from '../pages/User'

const routes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
    key: 'dashboard',
    title: 'MENU.DASHBOARD',
    exact: true,
    role: ['All'],
    icon: '/images/icons/nav/Dashboard.svg',
  },
  {
    key: 'task',
    title: 'MENU.WORKPLAN',
    role: ['Task'],
    path: '/workplan',
    icon: '/images/icons/nav/Workplan.svg',
    child: [
      {
        path: '/workplan/activity-report',
        element: <ActivityReportList />,
        key: 'activity-report-list',
        title: 'MENU.WORKPLAN.ACTIVITYREPORT',
        pages: [
          {
            path: '/workplan/activity-report',
            element: <ActivityReportList />,
            key: 'activity-report-list-page',
          },
          {
            path: '/workplan/activity-report/detail/:id',
            element: <ActivityReportDetail />,
            key: 'activity-report-detail-page',
          },
          {
            path: '/workplan/activity-report/create',
            element: <ActivityReportCreate />,
            key: 'activity-report-create-page',
          },
          {
            path: '/workplan/activity-report/edit/:id',
            element: <ActivityReportEdit />,
            key: 'activity-report-edit-page',
          },
        ],
      },
      {
        path: '/workplan/task',
        element: <TaskList />,
        key: 'task',
        title: 'MENU.WORKPLAN.TASK',
        pages: [
          {
            path: '/workplan/task',
            element: <TaskList />,
            key: 'task-list-page',
          },
          {
            path: '/workplan/task/detail/:id',
            element: <TaskDetail />,
            key: 'task-detail-page',
          },
          {
            path: '/workplan/task/edit/:id',
            element: <TaskEdit />,
            key: 'task-edit-page',
          },
          {
            path: '/workplan/task/create',
            element: <TaskCreate />,
            key: 'task-create-page',
          },
        ],
      },
    ],
  },
  {
    key: 'projects',
    title: 'MENU.PROJECTS',
    role: ['Project', 'Contract'],
    path: '/projects',
    icon: '/images/icons/nav/Projects.svg',
    child: [
      {
        path: '/projects/projects',
        element: <ProjectsList />,
        key: 'project-list',
        title: 'MENU.PROJECTS.PROJECTS',
        role: ['Project'],
        pages: [
          {
            path: '/projects/projects',
            element: <ProjectsList />,
            key: 'project-list-page',
          },
          {
            path: '/projects/projects/detail/:id',
            element: <ProjectsDetail />,
            key: 'project-detail-page',
          },
          {
            path: '/projects/projects/edit/:id',
            element: <ProjectsEdit />,
            key: 'project-edit-page',
          },
          {
            path: '/projects/projects/create',
            element: <ProjectsCreate />,
            key: 'project-create-page',
          },
        ],
      },
      {
        path: '/projects/estates',
        element: <EstateList />,
        key: 'estates',
        title: 'MENU.PROJECTS.ESTATES',
        role: ['Project'],
        pages: [
          {
            path: '/projects/estates',
            element: <EstateList />,
            key: 'estates-list-page',
          },
          {
            path: '/projects/estates/detail/:id',
            element: <EstateDetail />,
            key: 'estates-detail-page',
          },
          {
            path: '/projects/estates/edit/:id',
            element: <EstateEdit />,
            key: 'estates-edit-page',
          },
          {
            path: '/projects/estates/create',
            element: <EstateCreate />,
            key: 'estates-create-page',
          },
        ],
      },
      {
        path: '/projects/contracts',
        element: <ContractsList />,
        key: 'contracts-list',
        title: 'MENU.PROJECTS.CONTRACTS',
        role: ['Contract'],
        pages: [
          {
            path: '/projects/contracts',
            element: <ContractsList />,
            key: 'contracts-list-page',
          },
          {
            path: '/projects/contracts/detail/:id',
            element: <ContractsDetail />,
            key: 'contracts-detail-page',
          },
          {
            path: '/projects/contracts/edit/:id',
            element: <ContractsEdit />,
            key: 'contracts-edit-page',
          },
          {
            path: '/projects/contracts/create',
            element: <ContractsCreate />,
            key: 'contracts-create-page',
          },
        ],
      },
      {
        path: '/projects/construction-site',
        element: <ConstructionSiteList />,
        key: 'construction-site-list',
        title: 'MENU.PROJECTS.CONSTRUCTIONSITE',
        role: ['Project'],
        pages: [
          {
            path: '/projects/construction-site',
            element: <ConstructionSiteList />,
            key: 'construction-site-list-page',
          },
          {
            path: '/projects/construction-site/detail/:id',
            element: <ConstructionSiteDetail />,
            key: 'construction-site-detail-page',
          },
          {
            path: '/projects/construction-site/edit/:id',
            element: <ConstructionSiteEdit />,
            key: 'construction-site-edit-page',
          },
          {
            path: '/projects/construction-site/create',
            element: <ConstructionSiteCreate />,
            key: 'construction-site-create-page',
          },
        ],
      },
      {
        path: '/projects/block',
        element: <BlockList />,
        key: 'block-list',
        title: 'MENU.PROJECTS.BLOCK',
        role: ['Project'],
        pages: [
          {
            path: '/projects/block',
            element: <BlockList />,
            key: 'block-list-page',
          },
          {
            path: '/projects/block/detail/:id',
            element: <BlockDetail />,
            key: 'block-detail-page',
          },
          {
            path: '/projects/block/edit/:id',
            element: <BlockEdit />,
            key: 'block-edit-page',
          },
          {
            path: '/projects/block/create',
            element: <BlockCreate />,
            key: 'block-create-page',
          },
        ],
      },
      {
        path: '/projects/building-block-type',
        element: <BuildingBlockTypeList />,
        key: 'building-block-type-list',
        title: 'MENU.PROJECTS.BUILDINGBLOCKTYPE',
        role: ['Project'],
        pages: [
          {
            path: '/projects/building-block-type',
            element: <BuildingBlockTypeList />,
            key: 'building-block-type-list-page',
          },
          {
            path: '/projects/building-block-type/detail/:id',
            element: <BuildingBlockTypeDetail />,
            key: 'building-block-type-detail-page',
          },
          {
            path: '/projects/building-block-type/edit/:id',
            element: <BuildingBlockTypeEdit />,
            key: 'building-block-type-edit-page',
          },
          {
            path: '/projects/building-block-type/create',
            element: <BuildingBlockTypeCreate />,
            key: 'building-block-type-create-page',
          },
        ],
      },
      {
        path: '/projects/plot',
        element: <PlotList />,
        key: 'plot-list',
        title: 'MENU.PROJECTS.PLOT',
        role: ['Project'],
        pages: [
          {
            path: '/projects/plot',
            element: <PlotList />,
            key: 'plot-list-page',
          },
          {
            path: '/projects/plot/detail/:id',
            element: <PlotDetail />,
            key: 'plot-detail-page',
          },
          {
            path: '/projects/plot/edit/:id',
            element: <PlotEdit />,
            key: 'plot-edit-page',
          },
          {
            path: '/projects/plot/create',
            element: <PlotCreate />,
            key: 'plot-create-page',
          },
        ],
      },
    ],
  },
  {
    key: 'expenses',
    title: 'MENU.EXPENSES',
    role: ['Expense'],
    path: '/expenses',
    icon: '/images/icons/nav/Expenses.svg',
    child: [
      {
        path: '/expenses/expenses',
        element: <ExpensesList />,
        key: 'expenses-list',
        title: 'MENU.EXPENSES.EXPENSES',
        pages: [
          {
            path: '/expenses/expenses',
            element: <ExpensesList />,
            key: 'expenses-list-page',
          },
          {
            path: '/expenses/expenses/bank/detail/:id',
            element: <ExpensesBankDetail />,
            key: 'expenses-bank-detail-page',
          },
          {
            path: '/expenses/expenses/bank/edit/:id',
            element: <ExpensesBankEdit />,
            key: 'expenses-bank-edit-page',
          },
          {
            path: '/expenses/expenses/bank/create',
            element: <ExpensesBankCreate />,
            key: 'expenses-bank-create-page',
          },
          {
            path: '/expenses/expenses/detail/detail/:id',
            element: <ExpensesDetailDetail />,
            key: 'expenses-detail-detail-page',
          },
          {
            path: '/expenses/expenses/detail/edit/:id',
            element: <ExpensesDetailEdit />,
            key: 'expenses-detail-edit-page',
          },
          {
            path: '/expenses/expenses/detail/create',
            element: <ExpensesDetailCreate />,
            key: 'expenses-detail-create-page',
          },
          {
            path: '/expenses/expenses/fast/detail/:id',
            element: <ExpensesFastDetail />,
            key: 'expenses-fast-detail-page',
          },
          {
            path: '/expenses/expenses/fast/edit/:id',
            element: <ExpensesFastEdit />,
            key: 'expenses-fast-edit-page',
          },
          {
            path: '/expenses/expenses/fast/create',
            element: <ExpensesFastCreate />,
            key: 'expenses-fast-create-page',
          },
          {
            path: '/expenses/expenses/sundry/detail/:id',
            element: <ExpensesSundryDetail />,
            key: 'expenses-sundry-detail-page',
          },
          {
            path: '/expenses/expenses/sundry/edit/:id',
            element: <ExpensesSundryEdit />,
            key: 'expenses-sundry-edit-page',
          },
          {
            path: '/expenses/expenses/sundry/create',
            element: <ExpensesSundryCreate />,
            key: 'expenses-sundry-create-page',
          },
          {
            path: '/expenses/expenses/tax/detail/:id',
            element: <ExpensesTaxDetail />,
            key: 'expenses-tax-detail-page',
          },
          {
            path: '/expenses/expenses/tax/edit/:id',
            element: <ExpensesTaxEdit />,
            key: 'expenses-tax-edit-page',
          },
          {
            path: '/expenses/expenses/tax/create',
            element: <ExpensesTaxCreate />,
            key: 'expenses-tax-create-page',
          },
        ],
      },
      //{
      //  path: '/expenses/gib-invoices',
      //  element: <GibInvoicesList />,
      //  key: 'gib-invoices',
      //  title: 'MENU.EXPENSES.GIBINVOICES',
      //  pages: [
      //    {
      //      path: '/expenses/gib-invoices',
      //      element: <GibInvoicesList />,
      //      key: 'gib-invoices-list-page',
      //    },
      //    {
      //      path: '/expenses/gib-invoices/create/:id',
      //      element: <GibInvoicesCreate />,
      //      key: 'gib-invoices-edit-page',
      //    },
      //  ],
      //},
      {
        path: '/expenses/supplier',
        element: <SupplierList />,
        key: 'supplier',
        title: 'MENU.EXPENSES.SUPPLIER',
        pages: [
          {
            path: '/expenses/supplier',
            element: <SupplierList />,
            key: 'supplier-list-page',
          },
          {
            path: '/expenses/supplier/detail/:id',
            element: <SupplierDetail />,
            key: 'supplier-detail-page',
          },
          {
            path: '/expenses/supplier/edit/:id',
            element: <SupplierEdit />,
            key: 'supplier-edit-page',
          },
          {
            path: '/expenses/supplier/create',
            element: <SupplierCreate />,
            key: 'supplier-create-page',
          },
        ],
      },
      {
        path: '/expenses/subcontractor',
        element: <SubcontractorList />,
        key: 'subcontractor',
        title: 'MENU.EXPENSES.SUBCONTRACTOR',
        pages: [
          {
            path: '/expenses/subcontractor',
            element: <SubcontractorList />,
            key: 'subcontractor-list-page',
          },
          {
            path: '/expenses/subcontractor/detail/:id',
            element: <SubcontractorDetail />,
            key: 'subcontractor-detail-page',
          },
          {
            path: '/expenses/subcontractor/edit/:id',
            element: <SubcontractorEdit />,
            key: 'subcontractor-edit-page',
          },
          {
            path: '/expenses/subcontractor/create',
            element: <SubcontractorCreate />,
            key: 'subcontractor-create-page',
          },
        ],
      },
      {
        path: '/expenses/payment-request',
        element: <PaymentRequestList />,
        key: 'payment-request',
        title: 'MENU.EXPENSES.PAYMENTREQUEST',
        pages: [
          {
            path: '/expenses/payment-request',
            element: <PaymentRequestList />,
            key: 'payment-request-list-page',
          },
          {
            path: '/expenses/payment-request/detail/:id',
            element: <PaymentRequestDetail />,
            key: 'payment-request-detail-page',
          },
          {
            path: '/expenses/payment-request/edit/:id',
            element: <PaymentRequestEdit />,
            key: 'payment-request-edit-page',
          },
          {
            path: '/expenses/payment-request/create',
            element: <PaymentRequestCreate />,
            key: 'payment-request-create-page',
          },
        ],
      },
      {
        path: '/expenses/waiting-confirmation',
        element: <PaymentRequestList />,
        key: 'waiting-confirmation',
        title: 'MENU.EXPENSES.WAITINGCONFIRMATION',
        pages: [
          {
            path: '/expenses/waiting-confirmation',
            element: <WaitingConfirmationList />,
            key: 'waiting-confirmation-list-page',
          },
        ],
      },
    ],
  },
  {
    key: 'sales',
    title: 'MENU.SALES',
    role: ['Sale'],
    path: '/sales',
    icon: '/images/icons/nav/Sales.svg',
    child: [
      {
        path: '/sales/sales-invoices',
        element: <InvoicesList />,
        key: 'sales-invoices',
        title: 'MENU.SALES.SALES_INVOICES',
        pages: [
          {
            path: '/sales/sales-invoices',
            element: <InvoicesList />,
            key: 'sales-invoices-list-page',
          },
          {
            path: '/sales/sales-invoices/detail/:id',
            element: <InvoicesDetail />,
            key: 'sales-invoices-detail-page',
          },
          {
            path: '/sales/sales-invoices/edit/:id',
            element: <InvoicesEdit />,
            key: 'sales-invoices-edit-page',
          },
          {
            path: '/sales/sales-invoices/create',
            element: <InvoicesCreate />,
            key: 'sales-invoices-create-page',
          },
        ],
      },
      {
        path: '/sales/customers',
        element: <CustomersList />,
        key: 'customers',
        title: 'MENU.SALES.CUSTOMERS',
        pages: [
          {
            path: '/sales/customers',
            element: <CustomersList />,
            key: 'customers-list-page',
          },
          {
            path: '/sales/customers/detail/:id',
            element: <CustomersDetail />,
            key: 'customers-detail-page',
          },
          {
            path: '/sales/customers/edit/:id',
            element: <CustomersEdit />,
            key: 'customers-edit-page',
          },
          {
            path: '/sales/customers/create',
            element: <CustomersCreate />,
            key: 'customers-create-page',
          },
        ],
      },
      {
        path: '/sales/proposal',
        element: <ProposalList />,
        key: 'proposal',
        title: 'MENU.SALES.PROPOSAL',
        pages: [
          {
            path: '/sales/proposal',
            element: <ProposalList />,
            key: 'proposal-list-page',
          },
          {
            path: '/sales/proposal/detail/:id',
            element: <ProposalDetail />,
            key: 'proposal-detail-page',
          },
          {
            path: '/sales/proposal/edit/:id',
            element: <ProposalEdit />,
            key: 'proposal-edit-page',
          },
          {
            path: '/sales/proposal/create',
            element: <ProposalCreate />,
            key: 'proposal-create-page',
          },
        ],
      },
    ],
  },
  {
    key: 'cash',
    title: 'MENU.CASH',
    role: ['Bank'],
    path: '/cash',
    icon: '/images/icons/nav/Cash.svg',
    child: [
      {
        path: '/cash/bank-safes',
        element: <BankList />,
        key: 'bank-safes',
        title: 'MENU.CASH.BANK_SAFES',
        pages: [
          {
            path: '/cash/bank-safes',
            element: <BankList />,
            key: 'bank-list-page',
          },
          {
            path: '/cash/bank-safes/detail/:id',
            element: <BankDetail />,
            key: 'bank-detail-page',
          },
          {
            path: '/cash/bank-safes/edit/:id',
            element: <BankEdit />,
            key: 'bank-edit-page',
          },
          {
            path: '/cash/bank-safes/create',
            element: <BankCreate />,
            key: 'bank-create-page',
          },
        ],
      },
      {
        path: '/cash/checks',
        element: <ChecksList />,
        key: 'checks',
        title: 'MENU.CASH.CHECKS',
        pages: [
          {
            path: '/cash/checks',
            element: <ChecksList />,
            key: 'checks-list-page',
          },
          {
            path: '/cash/checks/detail/:id',
            element: <ChecksDetail />,
            key: 'checks-detail-page',
          },
          {
            path: '/cash/checks/edit/:id',
            element: <ChecksEdit />,
            key: 'checks-edit-page',
          },
          {
            path: '/cash/checks/create',
            element: <ChecksCreate />,
            key: 'checks-create-page',
          },
        ],
      },
      {
        path: '/cash/expense',
        element: <ExpenseList />,
        key: 'cash-expense',
        title: 'MENU.CASH.EXPENSE',
        pages: [
          {
            path: '/cash/expense',
            element: <ExpenseList />,
            key: 'cash-expense-list-page',
          },
          {
            path: '/cash/expense/detail/:id',
            element: <ExpenseDetail />,
            key: 'expense-detail-page',
          },
          {
            path: '/cash/expense/edit/:id',
            element: <ExpenseEdit />,
            key: 'expense-edit-page',
          },
          {
            path: '/cash/expense/create',
            element: <ExpenseCreate />,
            key: 'expense-create-page',
          },
        ],
      },
      {
        path: '/cash/sale',
        element: <SaleList />,
        key: 'cash-sale',
        title: 'MENU.CASH.SALE',
        pages: [
          {
            path: '/cash/sale',
            element: <SaleList />,
            key: 'cash-sale-list-page',
          },
          {
            path: '/cash/sale/detail/:id',
            element: <SaleDetail />,
            key: 'sale-detail-page',
          },
          {
            path: '/cash/sale/edit/:id',
            element: <SaleEdit />,
            key: 'sale-edit-page',
          },
          {
            path: '/cash/sale/create',
            element: <SaleCreate />,
            key: 'sale-create-page',
          },
        ],
      },
      {
        path: '/cash/guaranteeletter',
        element: <GuaranteeletterList />,
        key: 'guaranteeletter',
        title: 'MENU.CASH.GUARATEELETTER',
        pages: [
          {
            path: '/cash/guaranteeletter',
            element: <GuaranteeletterList />,
            key: 'guaranteeletter-list-page',
          },
          {
            path: '/cash/guaranteeletter/detail/:id',
            element: <GuaranteeletterDetail />,
            key: 'guaranteeletter-detail-page',
          },
          {
            path: '/cash/guaranteeletter/edit/:id',
            element: <GuaranteeletterEdit />,
            key: 'guaranteeletter-edit-page',
          },
          {
            path: '/cash/guaranteeletter/create',
            element: <GuaranteeletterCreate />,
            key: 'guaranteeletter-create-page',
          },
        ],
      },
      {
        path: '/cash/transactions',
        element: <TransactionsList />,
        key: 'transactions',
        title: 'MENU.CASH.TRANSACTIONS',
        pages: [
          {
            path: '/cash/transactions',
            element: <TransactionsList />,
            key: 'transactions-list-page',
          },
        ],
      },
      {
        path: '/cash/bank-payment-order',
        element: <BankPaymentOrderList />,
        key: 'cash-bank-payment-order',
        title: 'MENU.CASH.BANKPAYMENTORDER',
        pages: [
          {
            path: '/cash/bank-payment-order',
            element: <BankPaymentOrderList />,
            key: 'cash-bank-payment-order-list-page',
          },
          {
            path: '/cash/bank-payment-order/detail/:id',
            element: <BankPaymentOrderDetail />,
            key: 'bank-payment-order-detail-page',
          },
          {
            path: '/cash/bank-payment-order/edit/:id',
            element: <BankPaymentOrderEdit />,
            key: 'bank-payment-order-edit-page',
          },
          {
            path: '/cash/bank-payment-order/create',
            element: <BankPaymentOrderCreate />,
            key: 'bank-payment-order-create-page',
          },
        ],
      },
      {
        path: '/cash/unscheduled',
        element: <UnscheduledList />,
        key: 'unscheduled',
        title: 'MENU.CASH.UNSCHEDULED',
        pages: [
          {
            path: '/cash/unscheduled',
            element: <UnscheduledList />,
            key: 'unscheduled-list-page',
          },
        ],
      },
      {
        path: '/cash/checkout-approve',
        element: <CheckoutApproveList />,
        key: 'checkout-approve',
        title: 'MENU.CASH.CHECKOUTAPPROVE',
        pages: [
          {
            path: '/cash/checkout-approve',
            element: <CheckoutApproveList />,
            key: 'checkout-approve-list-page',
          },
        ],
      },
    ],
  },
  {
    key: 'staff',
    title: 'MENU.STAFF',
    role: ['Staff'],
    path: '/staff',
    icon: '/images/icons/nav/Staff.svg',
    child: [
      {
        path: '/staff/staff',
        element: <StaffList />,
        key: 'employees',
        title: 'MENU.STAFF.STAFF',
        pages: [
          {
            path: '/staff/staff',
            element: <StaffList />,
            key: 'staff-list-page',
          },
          {
            path: '/staff/staff/detail/:id',
            element: <StaffDetail />,
            key: 'staff-detail-page',
          },
          {
            path: '/staff/staff/edit/:id',
            element: <StaffEdit />,
            key: 'staff-edit-page',
          },
          {
            path: '/staff/staff/create',
            element: <StaffCreate />,
            key: 'staff-create-page',
          },
        ],
      },
      {
        path: '/staff/salary',
        element: <SalaryList />,
        key: 'salary',
        title: 'MENU.STAFF.SALARY_BONUS',
        pages: [
          {
            path: '/staff/salary',
            element: <SalaryList />,
            key: 'salary-list-page',
          },
          {
            path: '/staff/salary/detail/:id',
            element: <SalaryDetail />,
            key: 'salary-detail-page',
          },
          {
            path: '/staff/salary/edit/:id',
            element: <SalaryEdit />,
            key: 'salary-edit-page',
          },
          {
            path: '/staff/salary/create',
            element: <SalaryCreate />,
            key: 'salary-create-page',
          },
        ],
      },
      {
        path: '/staff/monthly-salary',
        element: <MonthlySalaryList />,
        key: 'monthly-salary',
        title: 'MENU.STAFF.MONTHLY_SALARY',
        pages: [
          {
            path: '/staff/monthly-salary',
            element: <MonthlySalaryList />,
            key: 'monthly-salary-list-page',
          },
          {
            path: '/staff/monthly-salary/detail',
            element: <MonthlySalaryDetail />,
            key: 'monthly-salary-detail-page',
          },
          {
            path: '/staff/monthly-salary/edit',
            element: <MonthlySalaryEdit />,
            key: 'monthly-salary-edit-page',
          },
          {
            path: '/staff/monthly-salary/create',
            element: <MonthlySalaryCreate />,
            key: 'monthly-salary-create-page',
          },
        ],
      },
      {
        path: '/staff/leave',
        element: <LeaveList />,
        key: 'leave',
        title: 'MENU.STAFF.LEAVE',
        pages: [
          {
            path: '/staff/leave',
            element: <LeaveList />,
            key: 'leave-list-page',
          },
          {
            path: '/staff/leave/detail/:id',
            element: <LeaveDetail />,
            key: 'leave-detail-page',
          },
          {
            path: '/staff/leave/edit/:id',
            element: <LeaveEdit />,
            key: 'leave-edit-page',
          },
          {
            path: '/staff/leave/create',
            element: <LeaveCreate />,
            key: 'leave-create-page',
          },
        ],
      },
      {
        path: '/staff/overtime',
        element: <OvertimeList />,
        key: 'overtime',
        title: 'MENU.STAFF.OVERTIME',
        pages: [
          {
            path: '/staff/overtime',
            element: <OvertimeList />,
            key: 'overtime-list-page',
          },
          {
            path: '/staff/overtime/detail/:id',
            element: <OvertimeDetail />,
            key: 'overtime-detail-page',
          },
          {
            path: '/staff/overtime/edit/:id',
            element: <OvertimeEdit />,
            key: 'overtime-edit-page',
          },
          {
            path: '/staff/overtime/create',
            element: <OvertimeCreate />,
            key: 'overtime-create-page',
          },
        ],
      },
    ],
  },
  {
    key: 'purchase',
    title: 'MENU.PURCHASE',
    role: ['Purchase'],
    path: '/purchase',
    icon: '/images/icons/nav/Purchase.svg',
    child: [
      {
        path: '/purchase/request',
        element: <RequestList />,
        key: 'request-list',
        title: 'MENU.PURCHASE.REQUEST',
        pages: [
          {
            path: '/purchase/request',
            element: <RequestList />,
            key: 'request-list-page',
          },
          {
            path: '/purchase/request/detail/:id',
            element: <RequestDetail />,
            key: 'request-detail-page',
          },
          {
            path: '/purchase/request/edit/:id',
            element: <RequestEdit />,
            key: 'request-edit-page',
          },
          {
            path: '/purchase/request/create',
            element: <RequestCreate />,
            key: 'request-create-page',
          },
        ],
      },
      {
        path: '/purchase/order',
        element: <OrderList />,
        key: 'order',
        title: 'MENU.PURCHASE.ORDER',
        pages: [
          {
            path: '/purchase/order',
            element: <OrderList />,
            key: 'order-list-page',
          },
          {
            path: '/purchase/order/detail/:id',
            element: <OrderDetail />,
            key: 'order-detail-page',
          },
          {
            path: '/purchase/order/edit/:id',
            element: <OrderEdit />,
            key: 'order-edit-page',
          },
          {
            path: '/purchase/order/create',
            element: <OrderCreate />,
            key: 'order-create-page',
          },
        ],
      },
    ],
  },
  {
    key: 'stock',
    title: 'MENU.STOCK',
    role: ['Stock'],
    path: '/stock',
    icon: '/images/icons/nav/Stock.svg',
    child: [
      {
        path: '/stock/product-services',
        element: <ProductList />,
        key: 'product-services',
        title: 'MENU.STOCK.PRODUCT_SERVICES',
        pages: [
          {
            path: '/stock/product-services',
            element: <ProductList />,
            key: 'product-services-list-page',
          },
          {
            path: '/stock/product-services/detail/:id',
            element: <ProductDetail />,
            key: 'product-services-detail-page',
          },
          {
            path: '/stock/product-services/edit/:id',
            element: <ProductEdit />,
            key: 'product-services-edit-page',
          },
          {
            path: '/stock/product-services/create',
            element: <ProductCreate />,
            key: 'product-services-create-page',
          },
        ],
      },
      {
        path: '/stock/warehouse',
        element: <WarehouseList />,
        key: 'warehouse',
        title: 'MENU.STOCK.WAREHOUSE',
        pages: [
          {
            path: '/stock/warehouse',
            element: <WarehouseList />,
            key: 'warehouse-list-page',
          },
          {
            path: '/stock/warehouse/detail/:id',
            element: <WarehouseDetail />,
            key: 'warehouse-detail-page',
          },
          {
            path: '/stock/warehouse/edit/:id',
            element: <WarehouseEdit />,
            key: 'warehouse-edit-page',
          },
          {
            path: '/stock/warehouse/create',
            element: <WarehouseCreate />,
            key: 'warehouse-create-page',
          },
        ],
      },
      {
        path: '/stock/warehouse-transfer',
        element: <TransferList />,
        key: 'warehouse-transfer',
        title: 'MENU.STOCK.WAREHOUSE_TRANSFER',
        pages: [
          {
            path: '/stock/warehouse-transfer',
            element: <TransferList />,
            key: 'warehouse-transfer-list-page',
          },
          {
            path: '/stock/warehouse-transfer/detail/:id',
            element: <TransferDetail />,
            key: 'warehouse-transfer-detail-page',
          },
          {
            path: '/stock/warehouse-transfer/edit/:id',
            element: <TransferEdit />,
            key: 'warehouse-transfer-edit-page',
          },
          {
            path: '/stock/warehouse-transfer/create',
            element: <TransferCreate />,
            key: 'warehouse-transfer-create-page',
          },
        ],
      },
      {
        path: '/stock/out-waybills',
        element: <OutWaybillsList />,
        key: 'out-waybills',
        title: 'MENU.STOCK.OUT_WAYBILLS',
        pages: [
          {
            path: '/stock/out-waybills',
            element: <OutWaybillsList />,
            key: 'out-waybills-list-page',
          },
          {
            path: '/stock/out-waybills/detail/:id',
            element: <OutWaybillsDetail />,
            key: 'out-waybills-detail-page',
          },
          {
            path: '/stock/out-waybills/edit/:id',
            element: <OutWaybillsEdit />,
            key: 'out-waybills-edit-page',
          },
          {
            path: '/stock/out-waybills/create',
            element: <OutWaybillsCreate />,
            key: 'out-waybills-create-page',
          },
        ],
      },
      {
        path: '/stock/in-waybills',
        element: <InWaybillsList />,
        key: 'in-waybills',
        title: 'MENU.STOCK.IN_WAYBILLS',
        pages: [
          {
            path: '/stock/in-waybills',
            element: <InWaybillsList />,
            key: 'in-waybills-list-page',
          },
          {
            path: '/stock/in-waybills/detail/:id',
            element: <InWaybillsDetail />,
            key: 'in-waybills-detail-page',
          },
          {
            path: '/stock/in-waybills/edit/:id',
            element: <InWaybillsEdit />,
            key: 'in-waybills-edit-page',
          },
          {
            path: '/stock/in-waybills/create',
            element: <InWaybillsCreate />,
            key: 'in-waybills-create-page',
          },
        ],
      },
    ],
  },
  {
    key: 'reporting',
    title: 'MENU.REPORTING',
    role: ['Reporting', 'Audit'],
    path: '/reporting',
    icon: '/images/icons/nav/Reporting.svg',
    child: [
      {
        path: '/reporting/dynamicreport',
        element: <DynamicReportList />,
        key: 'dynamicreport',
        title: 'MENU.REPORTING.DYMANICREPORT',
        pages: [
          {
            path: '/reporting/dynamicreport',
            element: <DynamicReportList />,
            key: 'dynamicreport-list',
          },
          {
            path: '/reporting/dynamicreport/expenseinvoice-paymentplan',
            element: <DynamicReportExpenseInvoicePaymentPlan />,
            key: 'dynamicreports-expenseinvoice-paymentplan',
          },
          {
            path: '/reporting/dynamicreport/saleinvoice-paymentplan',
            element: <DynamicReportSaleInvoicePaymentPlan />,
            key: 'dynamicreports-saleinvoice-paymentplan',
          },
          {
            path: '/reporting/dynamicreport/expense-with-balance',
            element: <DynamicReportExpenseWithBalance />,
            key: 'dynamicreports-expense-with-balance',
          },
          {
            path: '/reporting/dynamicreport/sale-with-balance',
            element: <DynamicReportSaleWithBalance />,
            key: 'dynamicreports-sale-with-balance',
          },
          {
            path: '/reporting/dynamicreport/checks-with-expenseinvoice',
            element: <DynamicReportChecksWithExpenseInvoice />,
            key: 'dynamicreports-checks-with-expenseinvoice',
          },
          {
            path: '/reporting/dynamicreport/taskcompleterate-by-project-report',
            element: <DynamicReportTaskCompleteRateByProject />,
            key: 'dynamicreports-taskcompleterate-by-project-report',
          },
          {
            path: '/reporting/dynamicreport/taskcompleterate-by-constructionsite-report',
            element: <DynamicReportTaskCompleteRateByConstructionSite />,
            key: 'dynamicreports-taskcompleterate-by-constructionsite-report',
          },
          {
            path: '/reporting/dynamicreport/taskandplotcompleterate-byconstructionsite-report',
            element: <DynamicReportTaskAndPlotCompleteRateByConstructionSite />,
            key: 'dynamicreports-taskandplotcompleterate-byconstructionsite-report',
          },
          {
            path: '/reporting/dynamicreport/monthly-payroll-report',
            element: <DynamicReportMonthlyPayroll />,
            key: 'dynamicreports-monthly-payroll-report',
          },
        ],
      },
      {
        path: '/reporting/saleinvoice-report',
        element: <SaleInvoiceReport />,
        key: 'saleinvoice-report',
        title: 'MENU.REPORTING.SALEINVOICE',
        claimType: 'beneksus_report',
        claimValue: 'saleinvoice-report',
      },
      {
        path: '/reporting/expenseinvoice-report',
        element: <ExpenseInvoiceReport />,
        key: 'expenseinvoice-report',
        title: 'MENU.REPORTING.EXPENSEINVOICE',
        claimType: 'beneksus_report',
        claimValue: 'expenseinvoice-report',
      },
      {
        path: '/reporting/workprogram-report',
        element: <WorkProgramReport />,
        key: 'workprogram-report',
        title: 'MENU.REPORTING.WORKPROGRAM',
        claimType: 'beneksus_report',
        claimValue: 'workprogram-report',
      },
      {
        path: '/reporting/audit-report',
        element: <AuditList />,
        key: 'audit-report',
        title: 'MENU.REPORTING.AUDIT',
        claimType: 'beneksus_report',
        claimValue: 'workprogram-report',
        role: ['Audit'],
      },
      {
        path: '/reporting/cashflow-report',
        element: <CashFlowReport />,
        key: 'cashflow-report',
        title: 'MENU.REPORTING.CASHFLOW',
        claimType: 'beneksus_report',
        claimValue: 'cash-flow-report',
      },
    ],
  },
  {
    key: 'admin',
    title: 'MENU.ADMIN',
    role: ['Admin'],
    path: '/admin',
    icon: '/images/icons/nav/Admin.svg',
    child: [
      {
        path: '/admin/company-info',
        element: <CompanyList />,
        key: 'company-info',
        title: 'MENU.ADMIN.COMPANY_INFO',
        pages: [
          {
            path: '/admin/company-info',
            element: <CompanyDetail />,
            key: 'company-info-detail-page',
          },
          {
            path: '/admin/company-info/edit',
            element: <CompanyEdit />,
            key: 'company-info-edit-page',
          },
        ],
      },
      {
        path: '/admin/users',
        element: <UsersList />,
        key: 'users',
        title: 'MENU.ADMIN.USERS',
        pages: [
          {
            path: '/admin/users',
            element: <UsersList />,
            key: 'users-list-page',
          },
          {
            path: '/admin/users/detail/:id',
            element: <UsersDetail />,
            key: 'users-detail-page',
          },
          {
            path: '/admin/users/edit/:id',
            element: <UsersEdit />,
            key: 'users-edit-page',
          },
          {
            path: '/admin/users/create',
            element: <UsersCreate />,
            key: 'users-create-page',
          },
        ],
      },
      {
        path: '/admin/categories',
        element: <CategoriesList />,
        key: 'categories',
        title: 'MENU.ADMIN.CATEGORIES',
      },
    ],
  },
  {
    key: 'usermenu',
    title: 'MENU.ADMIN.CATEGORIES',
    path: '/user',
    icon: '/images/icons/nav/Purchase.svg',
    role: ['All'],
    visibility: 'hidden',
    child: [
      {
        path: '/user/task',
        element: <UserTaskList />,
        key: 'task-list',
        title: 'MENU.PURCHASE.REQUEST',
        pages: [
          {
            path: '/user/task',
            element: <UserTaskList />,
            key: 'task-list-page',
          },
          {
            path: '/user/task/detail/:id',
            element: <UserTaskDetail />,
            key: 'task-detail-page',
          },
          {
            path: '/user/task/edit/:id',
            element: <UserTaskEdit />,
            key: 'task-edit-page',
          },
          {
            path: '/user/task/create',
            element: <UserTaskCreate />,
            key: 'task-create-page',
          },
        ],
      },
    ],
  }
]

export default routes
