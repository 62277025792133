/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../../core';

import { KTSVG, SourceType, detailPageUrl, DetailPage } from '../../../../helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { KTCard, KTCardBody, KTCardHeader, Notes, Timeline, PaymentStatus, DeleteModal, ArchiveModal, CheckoutDetail, Select, PaymentPlan, Category, Official } from '../../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { expenseinvoiceActions, lookupActions, checkoutActions } from '../../../../redux/actions';

import Dropdown from 'react-bootstrap/Dropdown';

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [isCollapse, setIsCollapse] = useState(true);
  const [checkoutId, setCheckouId] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()

  const { detail, isDeleted, isArchived, error } = useSelector(state => state.expenseinvoice);
  const { accountLookup } = useSelector(state => state.lookup);
  const { transaction, loading } = useSelector(state => state.checkout);
  const { paymentPlan, isDeleted: paymentDelete } = useSelector(state => state.paymentplan);
  const { officialExpense, isDeleted: officialExpenseDelete } = useSelector(state => state.official);

  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  const validSchema = Yup.object().shape({
    PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    FromAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Payment: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    ExpenseInvoiceId: Number(id),
    PaymentDate: new Date(),
    FromAccountId: '',
    Payment: 0,
    Name: '',
    IsOfficial: true
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      dispatch(checkoutActions.checkoutExpenseInvoicePayMoney(values));

      formik.setFieldValue('Payment', 0)
      formik.setFieldValue('Name', '')
    },
  })

  useEffect(() => {
    dispatch(expenseinvoiceActions.expenseinvoiceTaxGetId({ id }));
    dispatch(lookupActions.bankCashAccountGetAll());
  }, [id, transaction, paymentPlan, paymentDelete, officialExpense, officialExpenseDelete])

  const handleDelete = () => dispatch(expenseinvoiceActions.expenseinvoiceDelete({ id }));

  const handleArchive = () => dispatch(expenseinvoiceActions.expenseinvoiceArchiving({ id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.EXPENSES' }),
      path: '/expenses/expenses',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.EXPENSES.TAX.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">                            
                            <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='text-gray-400 text-hover-primary' target='_blank'>
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.projectName}
                            </Link>
                          </div>

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/general/gen014.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {intl.formatDate(new Date(detail?.editDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                          </div>

                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Category SourceId={Number(id)} SourceTypeId={SourceType.ExpenseInvoice} />
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/${pageUrl[3]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'EXPENSEINVOICE.GETFAST.TOTALPRICE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.totalPrice, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.PAID' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.totalPrice - detail?.balance, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'EXPENSEINVOICE.GETFAST.BALANCE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.balance, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'EXPENSEINVOICE.GETFAST.DEFERMENTDATE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'SALEINVOICE.CREATE.INVOICEKINDNAME' })}</div>
                          <div className="fw-bold fs-4">{detail?.invoiceKindName}</div>
                        </div> 

                        {detail?.totalOfficalExpense > 0 && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALOFFICIALEXPENSE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.totalOfficalExpense, { style: 'currency', currency: 'try' })}</div>
                        </div> }

                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  {detail?.checkoutModels?.length > 0 &&
                    <Tab eventKey="checkout" title={intl.formatMessage({ id: 'SALEINVOICE.CREATE.CHECKOUTS' })}>
                      <div className='table-responsive'>
                        <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
                          <thead>
                            <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                              <th>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.CHECKOUTMODELS.FROMACCOUNTNAME' })}</th>
                              <th>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.CHECKOUTMODELS.DESCRIPTION' })}</th>
                              <th>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.CHECKOUTMODELS.PAYMENTDATE' })}</th>
                              <th>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.CHECKOUTMODELS.CHECKOUTPAYMENTYPENAME' })}</th>
                              <th>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.CHECKOUTMODELS.ISOFFICIALNAME' })}</th>
                              <th>{intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })}</th>
                              <th className='text-end'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.CHECKOUTMODELS.PAYMENT' })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {detail?.checkoutModels.map((item, index) => <tr key={index} role="button" onClick={() => setCheckouId(item.id)}>
                              <td className='p-5'>
                                <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                  {item.fromAccountName}
                                </div>
                              </td>
                              <td className='p-5'>
                                <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                  {item?.name}
                                </div>
                              </td>
                              <td className='p-5'>
                                <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                  {intl.formatDate(new Date(item?.paymentDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                                </div>
                              </td>
                              <td className='p-5'>
                                <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                  {item.checkoutProcessTypeName}
                                </div>
                              </td>
                              <td className='p-5'>
                                <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                  {item.isOfficialName}
                                </div>
                              </td>
                              <td className='p-5'>
                                <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                  {item.lastModifiedBy} <br /> {intl.formatDate(new Date(item?.lastModifiedDate), { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                                </div>
                              </td>
                              <td className='p-5'>
                                <div className='text-dark fw-semibold d-block mb-1 fs-6 text-end'>
                                  {intl.formatNumber(item.amount, { style: 'currency', currency: 'try' })}
                                </div>
                              </td>
                            </tr>)}
                          </tbody>

                          <tfoot>
                            <tr className="align-middle fw-bold text-gray-700">
                              <th colSpan={2}>&nbsp;</th>
                              <th className="fs-4 p-5">
                                {intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.BALANCE' })}
                              </th>
                              <th className="text-end fs-4 text-nowrap p-5">{intl.formatNumber(detail?.balance, { style: 'currency', currency: 'try' })}</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </Tab>
                  }

                  <Tab eventKey="plans" title={intl.formatMessage({ id: 'SALEINVOICE.CREATE.PLANS' })}>
                    <PaymentPlan ExpenseInvoiceId={id} data={detail?.paymentPlans} ProjectId={detail?.projectId} />
                  </Tab>

                  <Tab eventKey="official" title={intl.formatMessage({ id: 'SALEINVOICE.CREATE.OFFICIAL' })}>
                    <Official ExpenseInvoiceId={id} data={detail?.officialExpenses} />
                  </Tab>
                </Tabs>

              </KTCardBody>
            </KTCard>
          </>}
        </div>

        <div className='col-xl-3'>
          {detail && <PaymentStatus
            balance={detail?.balance}
            delayDay={detail?.delayDay}
            payment={detail?.totalPrice}
          />}

          {detail?.balance > 0 && <KTCard className='mb-5'>
            <KTCardHeader title={intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.ADD' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

            <KTCardBody
              className='pt-5'
              collapseble
              isShow={isCollapse}
            >
              <form className='form position-relative mb-6' onSubmit={formik.handleSubmit} noValidate>
                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.PAYMENTDATE' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <div className="position-relative d-flex align-items-center">
                      <KTSVG
                        path='/images/icons/duotune/general/gen014.svg'
                        className='svg-icon svg-icon-2 position-absolute mx-4'
                      />
                      <Flatpickr
                        className="form-control form-control-solid ps-12"
                        options={{ "locale": Turkish }}
                        {...formik.getFieldProps('PaymentDate')}
                        onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                      />
                    </div>
                    {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.PaymentDate}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.TOACCOUNDID' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <Select
                      data={accountLookup}
                      required
                      {...formik.getFieldProps('FromAccountId')}
                      onChange={item => formik.setFieldValue('FromAccountId', Number(item?.value))}
                    />
                    {formik.touched.FromAccountId && formik.errors.FromAccountId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.FromAccountId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.ISOFFICIAL' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <div className="form-check form-switch form-check-custom form-check-solid">
                      <input 
                        className="form-check-input h-30px w-50px" 
                        type="checkbox" 
                        checked={formik.values.IsOfficial}
                        {...formik.getFieldProps('IsOfficial')}
                      />
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.PAYMENT' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <input
                      type='number'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('Payment')}
                      onFocus={event => event.target.select()}
                    />
                    {formik.touched.Payment && formik.errors.Payment && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.Payment}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.NAME' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <textarea
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      rows={2}
                      {...formik.getFieldProps('Name')}
                    />
                  </div>
                </div>

                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </KTCardBody>
          </KTCard>}

          <Notes SourceId={Number(id)} SourceTypeId={SourceType.ExpenseInvoice} />
          <Timeline isRefresh={loading} SourceId={Number(id)} SourceTypeId={SourceType.ExpenseInvoice} collapse={false} />
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal
        onClick={handleArchive}
        onOpen={showArchive}
        onClose={() => setShowArchive(false)}
        error={error}
      />

      <CheckoutDetail id={checkoutId} onClose={() => setCheckouId(null)} />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
