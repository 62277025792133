import { accountConstants } from '../constants';
import {
  //#region Account
  SearchBankCashAccountClient,
  GetBankCashAccountClient,
  DeleteAccountClient,
  CreateCashAccountClient,
  CreateBankAccountClient,
  ArchivingAccountClient,
  SearchTransactionsAccountClient,
  UpdateBankAccountClient,
  UpdateCashAccountClient,
  CurrentAccountStatementClient
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region Account
const searchBankCashAccountClient = new SearchBankCashAccountClient(SERVICE_URL);
const getBankCashAccountClient = new GetBankCashAccountClient(SERVICE_URL);
const deleteAccountClient = new DeleteAccountClient(SERVICE_URL);
const createCashAccountClient = new CreateCashAccountClient(SERVICE_URL);
const createBankAccountClient = new CreateBankAccountClient(SERVICE_URL);
const archivingAccountClient = new ArchivingAccountClient(SERVICE_URL);
const searchTransactionsAccountClient = new SearchTransactionsAccountClient(SERVICE_URL);
const updateBankAccountClient = new UpdateBankAccountClient(SERVICE_URL);
const updateCashAccountClient = new UpdateCashAccountClient(SERVICE_URL);
const currentAccountStatementClient = new CurrentAccountStatementClient(SERVICE_URL);
//#endregion

export const accountActions = {
  //#region Account
  accountGetAll,
  accountGetBankCash,
  accountDelete,
  accountCreateCash,
  accountCreateBank,
  accountUpdateCash,
  accountUpdateBank,
  accountArchiving,
  accountSearchTransactions,
  accountExcel,
  accountStatement
  //#endregion    
};

//#region Account
function accountGetAll(params) {
  return dispatch => {
    dispatch(request());
    
    searchBankCashAccountClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        account => dispatch(success(account)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: accountConstants.BANKCASHACCOUNT_GETALL_REQUEST } }
  function success(account) { return { type: accountConstants.BANKCASHACCOUNT_GETALL_SUCCESS, account } }
  function failure(error) { return { type: accountConstants.BANKCASHACCOUNT_GETALL_FAILURE, error } }
}

function accountExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchBankCashAccountClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: accountConstants.BANKCASHACCOUNT_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: accountConstants.BANKCASHACCOUNT_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: accountConstants.BANKCASHACCOUNT_EXCEL_FAILURE, error } }
}

function accountSearchTransactions(accountTypeId) {
  return dispatch => {
    dispatch(request());

    searchTransactionsAccountClient.search(accountTypeId)
      .then(
        accountList => dispatch(success(accountList)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: accountConstants.ACCOUNT_SEARCHTRANSACTION_REQUEST } }
  function success(accountList) { return { type: accountConstants.ACCOUNT_SEARCHTRANSACTION_SUCCESS, accountList } }
  function failure(error) { return { type: accountConstants.ACCOUNT_SEARCHTRANSACTION_FAILURE, error } }
}

function accountGetBankCash(id) {
  return dispatch => {
    dispatch(request(id));

    getBankCashAccountClient.get(id)
      .then(
        account => dispatch(success(account)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: accountConstants.BANKCASHACCOUNT_GETID_REQUEST, id } }
  function success(account) { return { type: accountConstants.BANKCASHACCOUNT_GETID_SUCCESS, account } }
  function failure(error) { return { type: accountConstants.BANKCASHACCOUNT_GETID_FAILURE, error } }
}

function accountDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteAccountClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: accountConstants.ACCOUNT_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: accountConstants.ACCOUNT_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: accountConstants.ACCOUNT_DELETE_FAILURE, error } }
}

function accountArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingAccountClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: accountConstants.ACCOUNT_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: accountConstants.ACCOUNT_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: accountConstants.ACCOUNT_ARCHIVE_FAILURE, error } }
}

function accountCreateCash(params) {
  return dispatch => {
    dispatch(request(params));

    createCashAccountClient.create(params)
      .then(
        account => dispatch(success(account)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: accountConstants.CASHACCOUNT_CREATE_REQUEST, params } }
  function success(account) { return { type: accountConstants.CASHACCOUNT_CREATE_SUCCESS, account } }
  function failure(error) { return { type: accountConstants.CASHACCOUNT_CREATE_FAILURE, error } }
}

function accountUpdateCash(params) {
  return dispatch => {
    dispatch(request(params));

    updateCashAccountClient.update(params)
      .then(
        account => dispatch(success(account, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: accountConstants.CASHACCOUNT_UPDATE_REQUEST, params } }
  function success(account, params) { return { type: accountConstants.CASHACCOUNT_UPDATE_SUCCESS, account, params } }
  function failure(error, params) { return { type: accountConstants.CASHACCOUNT_UPDATE_FAILURE, error, params } }
}

function accountCreateBank(params) {
  return dispatch => {
    dispatch(request(params));

    createBankAccountClient.create(params)
      .then(
        account => dispatch(success(account)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: accountConstants.BANKACCOUNT_CREATE_REQUEST, params } }
  function success(account) { return { type: accountConstants.BANKACCOUNT_CREATE_SUCCESS, account } }
  function failure(error) { return { type: accountConstants.BANKACCOUNT_CREATE_FAILURE, error } }
}

function accountUpdateBank(params) {
  return dispatch => {
    dispatch(request(params));

    updateBankAccountClient.update(params)
      .then(
        account => dispatch(success(account, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: accountConstants.BANKACCOUNT_UPDATE_REQUEST, params } }
  function success(account, params) { return { type: accountConstants.BANKACCOUNT_UPDATE_SUCCESS, account, params } }
  function failure(error, params) { return { type: accountConstants.BANKACCOUNT_UPDATE_FAILURE, error, params } }
}

function accountStatement(params) {
  return dispatch => {
    dispatch(request(params));

    currentAccountStatementClient.get(params)
      .then(
        statement => dispatch(success(statement)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: accountConstants.CURRENTACCOUNT_GET_REQUEST, params } }
  function success(statement) { return { type: accountConstants.CURRENTACCOUNT_GET_SUCCESS, statement } }
  function failure(error) { return { type: accountConstants.CURRENTACCOUNT_GET_FAILURE, error } }
}
//#endregion
