/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik, FormikProvider, FieldArray} from 'formik'
import classNames from 'classnames'
import Dropdown from 'react-bootstrap/Dropdown';
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTSVG, InvoiceType, TaxType, WitholdingType } from '../../../../helpers';
import { KTCard, KTCardBody, Select, SelectCreatable, InputPrice } from '../../../../components';
import { PageTitle } from '../../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, expenseinvoiceActions, productActions } from '../../../../redux/actions';

function Edit() {
  const intl = useIntl()
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { productLookup, projectLookup, warehouseLookup, ProductUnit, DiscountType, staffLookup, InvoiceKind} = useSelector(state => state.lookup);
  const {isRedirect, detail, loading} = useSelector(state => state.expenseinvoice);
  const { detail: product, isRedirect: createProduct } = useSelector(state => state.product);
	const dispatch = useDispatch();
  const [changeItem, setChangeItem] = useState();
  const [changeData, setChangeData] = useState();
  const [changeProduct, setChangeProduct] = useState();
  const [createItem, setCreateItem] = useState();
  const [visibiltyItem, setVisibiltyItem] = useState([{
    Discount: false,
    Description: false,
    WitholdingRate: false
  }]);

  useEffect(() => {
    dispatch(expenseinvoiceActions.expenseinvoiceDetailGetId({id}));
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.allProductLookup());
    dispatch(lookupActions.warehouseLookup());
    dispatch(lookupActions.basicLookup('ProductUnit'));
    dispatch(lookupActions.basicLookup('DiscountType'));
    dispatch(lookupActions.staffGetAll());
    dispatch(lookupActions.basicLookup('InvoiceKind'));
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    InvoiceKindId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ExpenseInvoiceItems: Yup.array().of(Yup.object().shape({
        ProductId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
        Amount: Yup.number().typeError(intl.formatMessage({id: 'REQUIRED.VALID' })).required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
        UnitPrice: Yup.number().typeError(intl.formatMessage({id: 'REQUIRED.VALID' })).required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
        ProductUnitId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
        TaxId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
      })
    )
  })

  const [formValues] = useState({
    Id: id,
    Name: '',
    ProjectId: null,
    EditDate: new Date(),
    DefermentDate: new Date(),
    InvoiceNumber: null,
    SubTotalPrice: 0,
    ExpenseFile: null,
    RelatedStaffId: '',
    TotalTax: 0,
    TotalDiscount: 0,
    GrossTotalPrice: 0,
    TotalWitholding: 0,
    TotalPrice: 0,
    InvoiceTypeId: 1,
    InvoiceKindId: '',
    ExpenseInvoiceItems: [{
      Id: 0,
      ProductId: null,
      WarehouseId: null,
      Amount: 1,
      UnitPrice: 0,
      ProductUnitId: 1,
      TaxId: 6,
      TotalTax: null,
      SubTotalPrice: 0,
      TotalPrice: 0,
      Description: null,
      DiscountTypeId: 1,
      DiscountRate: 0,
      Discount: null,
      WitholdingRateId: null,
      Witholding: null,
    }],
  })
  
  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(expenseinvoiceActions.expenseinvoiceDetailUpdate(values));
      resetForm()
    },
  })

  useEffect(() => {
    for (const property in detail) {
      const propVal = property.charAt(0).toUpperCase() + property.slice(1);

      if (property == 'expenseInvoiceItemModels') {
        detail['expenseInvoiceItemModels'].map((item, index) => {
          for (const invoice in item) {
            const propValArr = invoice.charAt(0).toUpperCase() + invoice.slice(1);
            formik.setFieldValue(`ExpenseInvoiceItems[${index}][${propValArr}]`, item[invoice]) 
          }
        })
      }

      for (const values in formValues) {
        if (values === propVal) {
          formik.setFieldValue(propVal, property === 'editDate' || property === 'defermentDate' ? new Date(detail[property]) : detail[property])
        } else if (values === 'File') {
          formik.setFieldValue('File', detail['fileUrl'])
        }
      }
    }
  }, [detail])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.EXPENSES' }),
      path: '/expenses/expenses',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'PAGE_TITLE.DETAIL' }),
      path: `/expenses/expenses/detail/detail/${id}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (isRedirect) navigate(`/${pageUrl[1]}/${pageUrl[2]}/${pageUrl[3]}/detail/${id}`)
  }, [isRedirect])

  const onChangeItem = (target, value, index) => {
    setChangeItem({index, target})
    formik.setFieldValue(`ExpenseInvoiceItems[${index}][${target}]`, value);
  }

  const sumArrayItem = (item) => {
    return formik.values.ExpenseInvoiceItems.reduce((a,v) =>  a = a + v[item], 0 )
  }

  useEffect(() => {
    const expenseInvoiceItems = formik.values.ExpenseInvoiceItems[changeItem?.index];

    if (expenseInvoiceItems) {
      const tax = TaxType.find(item => item.id == expenseInvoiceItems.TaxId)
      const unitPrice = (expenseInvoiceItems.TotalPrice / (1 + (Number((tax?.value || 0)) / 100)) / expenseInvoiceItems.Amount);
      const subTotalPrice = changeItem.target == 'TotalPrice' ? unitPrice * expenseInvoiceItems.Amount : expenseInvoiceItems.UnitPrice * expenseInvoiceItems.Amount;
      const discount = expenseInvoiceItems.DiscountTypeId == 1 ? ((subTotalPrice * expenseInvoiceItems.DiscountRate) / 100) : expenseInvoiceItems.DiscountRate;
      const totalTax = ((subTotalPrice - discount) * (tax?.value || 0)) / 100;
      const witholdingRate = WitholdingType.find(item => item.id == expenseInvoiceItems.WitholdingRateId);
      const witholding = (totalTax * (witholdingRate?.value || 0)) / 100;
      const totalPrice = (subTotalPrice + totalTax) - (witholding + discount);
      
      if (changeItem.target == 'TotalPrice') {
        formik.setFieldValue(`ExpenseInvoiceItems[${changeItem?.index}].UnitPrice`, unitPrice);
        formik.setFieldValue(`ExpenseInvoiceItems[${changeItem?.index}].TotalPrice`, expenseInvoiceItems.TotalPrice);

        setChangeData(expenseInvoiceItems.TotalPrice)
      } else {
        formik.setFieldValue(`ExpenseInvoiceItems[${changeItem?.index}].TotalPrice`, totalPrice);
        
        setChangeData(totalPrice)
      }

      formik.setFieldValue(`ExpenseInvoiceItems[${changeItem?.index}].SubTotalPrice`, subTotalPrice);
      formik.setFieldValue(`ExpenseInvoiceItems[${changeItem?.index}].Discount`, discount);
      formik.setFieldValue(`ExpenseInvoiceItems[${changeItem?.index}].TotalTax`, totalTax);
      formik.setFieldValue(`ExpenseInvoiceItems[${changeItem?.index}].Witholding`, witholding);
    }
    
  }, [changeItem])

  useEffect(() => {
    formik.setFieldValue('SubTotalPrice', sumArrayItem('SubTotalPrice'));
    formik.setFieldValue('TotalTax', sumArrayItem('TotalTax'));
    formik.setFieldValue('TotalDiscount', sumArrayItem('Discount'));
    formik.setFieldValue('GrossTotalPrice', (sumArrayItem('SubTotalPrice') - sumArrayItem('Discount')));
    formik.setFieldValue('TotalWitholding', sumArrayItem('Witholding'));
    formik.setFieldValue('TotalPrice', sumArrayItem('TotalPrice'));
  }, [changeData])

  const onClickNewItem = (arrayHelpers) => {
    arrayHelpers.push({
      Id: 0,
      ProductId: null,
      WarehouseId: null,
      Amount: 1,
      UnitPrice: 0,
      ProductUnitId: 1,
      TaxId: 6,
      TotalTax: null,
      SubTotalPrice: 0,
      TotalPrice: 0,
      Description: null,
      DiscountTypeId: 1,
      DiscountRate: 0,
      Discount: null,
      WitholdingRateId: null,
      Witholding: null,
    })
    setVisibiltyItem([...visibiltyItem, {
      Discount: false,
      Description: false,
      WitholdingRate: false
    }])
  }

  const onClickVisibityItem = (item, index) => {
    const newArr = [...visibiltyItem]
    newArr[index][item] = !newArr[index][item];
    setVisibiltyItem(newArr)
  }

  const onChangeProduct = (item, index) => {
    formik.setFieldValue(`ExpenseInvoiceItems[${index}].ProductId`, Number(item?.value))
    setChangeProduct(index)
    dispatch(productActions.productGetInfo({Id: item?.value}));
  }

  useEffect(() => {
    if (product) {
      formik.setFieldValue(`ExpenseInvoiceItems[${changeProduct}].ProductUnitId`, product?.productUnitId);
      formik.setFieldValue(`ExpenseInvoiceItems[${changeProduct}].UnitPrice`, product?.baseSalePrice);
      formik.setFieldValue(`ExpenseInvoiceItems[${changeProduct}].TaxId`, product?.taxId);
      setChangeItem({index: changeProduct, target: 'UnitPrice'})
    }
  }, [product])

  const onClickFastProduct = (Name, Index) => {
    dispatch(productActions.productCreateFast({Name}));
    setCreateItem({Name, Index})
  }

  useEffect(() => {
    if (createProduct) dispatch(lookupActions.allProductLookup());
  }, [createProduct])

  useEffect(() => {
    if (productLookup && createProduct) {
      formik.setFieldValue(`ExpenseInvoiceItems[${createItem?.Index}].ProductId`, createProduct.id);
    }
  }, [productLookup, createProduct])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.EXPENSES.DETAIL.EDIT' })}</PageTitle>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <KTCard>          
            <KTCardBody>
              <FormikProvider value={formik}>
                {detail && <form className='form' onSubmit={formik.handleSubmit} noValidate>

                  <div className='row mb-6'>
                    <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.NAME' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        required
                        disabled={formik.isSubmitting}
                        {...formik.getFieldProps('Name')}
                      />
                      {formik.touched.Name && formik.errors.Name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.Name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.COMPANYID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <span className="fw-bold fs-6">{detail.companyName}</span>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.PROJECTID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select 
                        data={projectLookup}
                        required
                        {...formik.getFieldProps('ProjectId')}
                        onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                      />
                    </div>
                    {formik.touched.ProjectId && formik.errors.ProjectId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.ProjectId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  
                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.INVOICEKINDNAME' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select
                        data={InvoiceKind}
                        required
                        {...formik.getFieldProps('InvoiceKindId')}
                        onChange={item => formik.setFieldValue('InvoiceKindId', Number(item?.value))}
                      />
                      {formik.touched.InvoiceKind && formik.errors.InvoiceKind && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.InvoiceKind}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.INVOICETYPEID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container d-flex gap-10'>
                      {
                        InvoiceType.map((item, index) => {
                          return (
                            <div className="form-check form-check-custom form-check-solid form-check-sm" key={index}>
                              <input 
                                className="form-check-input" 
                                name='InvoiceTypeId' 
                                type="radio" 
                                value={item.Id} 
                                id={`InvoiceTypeId-${index}`}
                                onChange={() => formik.setFieldValue("InvoiceTypeId", item.Id)}
                                checked={item.Id === formik.values.InvoiceTypeId}
                              />
                              <label className="form-check-label" htmlFor={`InvoiceTypeId-${index}`}>
                                {intl.formatMessage({id: item.Name })}
                              </label>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EDITDATE' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <div className="position-relative d-flex align-items-center">
                        <KTSVG
                          path='/images/icons/duotune/general/gen014.svg'
                          className='svg-icon svg-icon-2 position-absolute mx-4'
                        />
                        <Flatpickr 
                          className="form-control form-control-solid ps-12"
                          options={{ "locale": Turkish }}
                          {...formik.getFieldProps('EditDate')}
                          onChange={([date]) => formik.setFieldValue('EditDate', date)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.DEFERMENTDATE' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <div className="position-relative d-flex align-items-center">
                        <KTSVG
                          path='/images/icons/duotune/general/gen014.svg'
                          className='svg-icon svg-icon-2 position-absolute mx-4'
                        />
                        <Flatpickr 
                          className="form-control form-control-solid ps-12"
                          options={{ "locale": Turkish }}
                          {...formik.getFieldProps('DefermentDate')}
                          onChange={([date]) => formik.setFieldValue('DefermentDate', date)}
                        />
                      </div>
                    </div>
                  </div>                 

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.RELATEDSTAFFID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select 
                        data={staffLookup}
                        {...formik.getFieldProps('RelatedStaffId')}
                        onChange={item => formik.setFieldValue('RelatedStaffId', Number(item?.value))}
                      />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATEDETAIL.INVOICENUMBER' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        disabled={formik.isSubmitting}
                        {...formik.getFieldProps('InvoiceNumber')}
                      />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.FILEID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <label htmlFor='ExpenseFile' className='btn btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark position-relative overflow-hidden'>
                        <input 
                          className='position-absolute z-index-2 opacity-0 w-1px h-1px'
                          type='file' 
                          accept='image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                          id={'ExpenseFile'}
                          name={'ExpenseFile'}
                          onChange={(event) => formik.setFieldValue('ExpenseFile', event.currentTarget.files[0])}
                        />
                        <KTSVG
                          path='/images/icons/duotune/communication/com008.svg'
                          className='svg-icon-3 position-relative z-index-1'
                        />
                        {intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.FILEID' })}
                      </label>
                      
                    </div>
                  </div>

                  <FieldArray
                    name="ExpenseInvoiceItems"
                    render={(arrayHelpers) => (
                      <>
                        <div className="table-responsive">
                          <table className="table g-5 gs-0 mb-0 fw-bold text-gray-700" >
                            <thead>
                              <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                                <th>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.PRODUCTID' })}</th>
                                {formik.values.InvoiceTypeId === 1 && <th>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.WAREHOUSEID' })}</th> }
                                <th>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.AMOUNT' })}</th>
                                <th>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.PRODUCTUNITID' })}</th>
                                <th>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.UNITPRICE' })}</th>
                                <th>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.TAXID' })}</th>
                                <th>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.TOTALPRICE' })}</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                        
                              {formik.values.ExpenseInvoiceItems.map((item, index) => (
                                <tr className="border-bottom border-bottom-dashed" key={index}>
                                  <td className="pe-7 ps-1">
                                    <SelectCreatable 
                                      data={productLookup} 
                                      name={`ExpenseInvoiceItems[${index}].ProductId`}
                                      value={formik.values.ExpenseInvoiceItems[index].ProductId}
                                      required
                                      onChange={item => onChangeProduct(item, index)}
                                      onCreate={item => onClickFastProduct(item, index)}
                                    />

                                    {visibiltyItem[index]?.Description && <input
                                      type='text'
                                      className='form-control form-control-solid mt-2'
                                      autoComplete='off'
                                      disabled={formik.isSubmitting}
                                      placeholder={intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.DESCRIPTION' })}
                                      name={`ExpenseInvoiceItems[${index}].Description`}
                                      value={formik.values.ExpenseInvoiceItems[index].Description}
                                      onChange={formik.handleChange}
                                    /> }
                                  </td>

                                  {formik.values.InvoiceTypeId === 1 && <td className="ps-0">
                                    <Select 
                                      data={warehouseLookup} 
                                      name={`ExpenseInvoiceItems[${index}].WarehouseId`}
                                      value={formik.values.ExpenseInvoiceItems[index].WarehouseId}
                                      required
                                      onChange={item => formik.setFieldValue(`ExpenseInvoiceItems[${index}].WarehouseId`, Number(item?.value))}
                                    />
                                  </td> }

                                  <td className="ps-0">
                                    <input
                                      type='number'
                                      className='form-control form-control-solid'
                                      autoComplete='off'
                                      disabled={formik.isSubmitting}
                                      placeholder={intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.AMOUNT' })}
                                      name={`ExpenseInvoiceItems[${index}].Amount`}
                                      value={formik.values.ExpenseInvoiceItems[index].Amount}
                                      required
                                      onChange={event => onChangeItem('Amount', Number(event.target.value), index)}
                                      onFocus={event => event.target.select()}
                                    />

                                    {visibiltyItem[index]?.Discount && <div className="input-group mt-2">
                                      <input
                                        type='number'
                                        className='form-control form-control-solid'
                                        autoComplete='off'
                                        disabled={formik.isSubmitting}
                                        placeholder={intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.DISCOUNTRATE' })}
                                        name={`ExpenseInvoiceItems[${index}].DiscountRate`}
                                        value={formik.values.ExpenseInvoiceItems[index].DiscountRate}
                                        onChange={event => onChangeItem('DiscountRate', Number(event.target.value), index)}
                                        onFocus={event => event.target.select()}
                                      />
                                      <Select 
                                        data={DiscountType} 
                                        name={`ExpenseInvoiceItems[${index}].DiscountTypeId`}
                                        value={formik.values.ExpenseInvoiceItems[index].DiscountTypeId}
                                        required
                                        onChange={item => onChangeItem('DiscountTypeId', Number(item?.value), index)}
                                      />
                                    </div>}
                                  </td>

                                  <td className="ps-0">
                                    <Select 
                                      data={ProductUnit} 
                                      name={`ExpenseInvoiceItems[${index}].ProductUnitId`}
                                      value={formik.values.ExpenseInvoiceItems[index].ProductUnitId}
                                      required
                                      onChange={item => onChangeItem('ProductUnitId', Number(item?.value), index)}
                                    />

                                    {visibiltyItem[index]?.WitholdingRate && <Select 
                                      data={WitholdingType} 
                                      className={'mt-2'}
                                      name={`ExpenseInvoiceItems[${index}].WitholdingRateId`}
                                      value={formik.values.ExpenseInvoiceItems[index].WitholdingRateId}
                                      required
                                      onChange={item => onChangeItem('WitholdingRateId', Number(item?.value), index)}
                                    />}
                                  </td>

                                  <td className="ps-0">
                                    <InputPrice
                                      placeholder={intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.UNITPRICE' })}
                                      name={`ExpenseInvoiceItems[${index}].UnitPrice`}
                                      required
                                      value={formik.values.ExpenseInvoiceItems[index].UnitPrice}
                                      onChange={value => onChangeItem('UnitPrice', Number(value), index)}
                                    />
                                  </td>

                                  <td className="ps-0">
                                    <Select 
                                      data={TaxType} 
                                      name={`ExpenseInvoiceItems[${index}].TaxId`}
                                      value={formik.values.ExpenseInvoiceItems[index].TaxId}
                                      required
                                      onChange={item => onChangeItem('TaxId', Number(item?.value), index)}
                                    />
                                  </td>

                                  <td className='ps-0'>
                                    <InputPrice
                                      placeholder={intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.TOTALPRICE' })}
                                      name={`ExpenseInvoiceItems[${index}].TotalPrice`}
                                      value={formik.values.ExpenseInvoiceItems[index].TotalPrice}
                                      onChange={value => onChangeItem('TotalPrice', Number(value), index)}
                                    />
                                  </td>

                                  <td className="pt-5 text-end">
                                    <div className='d-flex'>
                                      <Dropdown autoClose='outside'>
                                        <Dropdown.Toggle variant='link' className='btn btn-sm btn-icon btn-active-color-info'>
                                          <KTSVG path='/images/icons/duotune/arrows/arr009.svg' className='svg-icon-2' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item 
                                            onClick={() => onClickVisibityItem('Discount', index)}
                                            className={classNames({'active': visibiltyItem[index]?.Discount})}
                                          >
                                            {intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.DISCOUNT' })}
                                          </Dropdown.Item>
                                          <Dropdown.Item 
                                            onClick={() => onClickVisibityItem('Description', index)}
                                            className={classNames({'active': visibiltyItem[index]?.Description})}
                                          >
                                            {intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.DESCRIPTION' })}
                                          </Dropdown.Item>
                                          <Dropdown.Item 
                                            onClick={() => onClickVisibityItem('WitholdingRate', index)}
                                            className={classNames({'active': visibiltyItem[index]?.WitholdingRate})}
                                          >
                                            {intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.EXPENSEINVOICEITEMS.WITHOLDINGRATEID' })}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <button 
                                        type="button" 
                                        className="btn btn-sm btn-icon btn-active-color-primary" 
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            
                            </tbody>
                            <tfoot>
                              <tr className="border-top border-top-dashed align-middle fs-6 fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}>
                                  <button 
                                    type="button" 
                                    className="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success" 
                                    onClick={() => onClickNewItem(arrayHelpers)}
                                  >
                                    <KTSVG path='/images/icons/duotune/arrows/arr009.svg' className='svg-icon-2' />
                                    {intl.formatMessage({id: 'ADD_NEW_LINE' })}
                                  </button>
                                </th>
                                <th className="border-bottom border-bottom-dashed ps-0 fs-5" colSpan={2}>
                                  {intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.SUBTOTALPRICE' })}
                                </th>
                                <th className="border-bottom border-bottom-dashed text-end">{intl.formatNumber(formik.values.SubTotalPrice, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>

                              {formik.values.TotalDiscount > 0 && <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="border-bottom border-bottom-dashed ps-0 fs-5" colSpan={2}>
                                  {intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.TOTALDISCOUNT' })}
                                </th>
                                <th className="border-bottom border-bottom-dashed text-end">{intl.formatNumber(formik.values.TotalDiscount, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr> }

                              <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="border-bottom border-bottom-dashed ps-0 fs-5" colSpan={2}>
                                  {intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.GROSSTOTALPRICE' })}
                                </th>
                                <th className="border-bottom border-bottom-dashed text-end">{intl.formatNumber(formik.values.GrossTotalPrice, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>

                              <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="border-bottom border-bottom-dashed ps-0 fs-5" colSpan={2}>
                                  {intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.TOTALTAX' })}
                                </th>
                                <th className="border-bottom border-bottom-dashed text-end">{intl.formatNumber(formik.values.TotalTax, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>

                              {formik.values.TotalWitholding > 0 && <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="border-bottom border-bottom-dashed ps-0 fs-5" colSpan={2}>
                                  {intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.TOTALWITHOLDING' })}
                                </th>
                                <th className="border-bottom border-bottom-dashed text-end">{intl.formatNumber(formik.values.TotalWitholding, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr> }

                              <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="fs-4 ps-0" colSpan={2}>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.TOTALPRICE' })}</th>
                                <th className="text-end fs-4 text-nowrap">{intl.formatNumber(formik.values.TotalPrice, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>

                              {formik.values.TotalWitholding > 0 && <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="fs-4 ps-0" colSpan={2}>{intl.formatMessage({id: 'EXPENSEINVOICE.CREATEDETAIL.TOTALPRICEPLUSWITHHOLDING' })}</th>
                                <th className="text-end fs-4 text-nowrap">{intl.formatNumber(formik.values.TotalPrice + formik.values.TotalWitholding, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>}
                            </tfoot>
                          </table>
                        </div>
                      </>
                    )}
                  />

                  <div className='row pt-15'>
                    <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>                      
                      <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}/${pageUrl[3]}/detail/${id}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                      {!detail?.isGibInvoice && <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>}
                    </div>
                  </div>
                </form> }
              </FormikProvider>
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {Edit};
