import React from 'react'
import {NumericFormat} from 'react-number-format'

const InputPrice = (props) => {
  const {onChange, ...rest} = props

  const handleValueChange = (values) => {
    const {floatValue} = values
    onChange && onChange(floatValue || 0)
  }

  return (
    <NumericFormat
      thousandSeparator='.'
      decimalSeparator=','
      decimalScale={2}
      fixedDecimalScale={true}
      suffix=' ₺'
      onValueChange={handleValueChange}
      className='form-control form-control-solid mb-3 mb-lg-0'
      {...rest}
    />
  )
}

export {InputPrice}
