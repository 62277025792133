/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTSVG } from '../../../../helpers';
import { KTCard, KTCardBody, Select, InputPrice } from '../../../../components';
import { PageTitle } from '../../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, expenseinvoiceActions, categoryActions } from '../../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { projectLookup, accountLookup, ExpensePaymentStatus, InvoiceKind } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.expenseinvoice);
  const { category } = useSelector((state) => state.category)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.bankCashAccountGetAll());
    dispatch(lookupActions.basicLookup('ExpensePaymentStatus'));
    dispatch(categoryActions.categoryGetAll({ SourceTypeId: 8 }))
    dispatch(lookupActions.basicLookup('InvoiceKind'));
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    InvoiceKindId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [expensePaymentStatus, setExpensePaymentStatus] = useState()

  const [formValues] = useState({
    Name: '',
    ProjectId: null,
    EditDate: new Date(),
    DefermentDate: new Date(),
    TotalPrice: 0,
    ExpensePaymentStatusId: null,
    FromAccountId: null,
    CheckoutName: '',
    PaymentDate: new Date(),
    CategoryId: null,
    InvoiceKindId: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(expenseinvoiceActions.expenseinvoiceSundryCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.EXPENSES' }),
      path: '/expenses/expenses',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/${pageUrl[3]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  console.log(formik.values)

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.EXPENSES.SUNDRY.ADD' })}</PageTitle>
      <KTCard>       
        <KTCardBody>
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATESUNDRY.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATESUNDRY.CATEGORY' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={category}
                  {...formik.getFieldProps('CategoryId')}
                  onChange={item => formik.setFieldValue('CategoryId', Number(item?.value))}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATESUNDRY.PROJECT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={projectLookup}
                  required
                  {...formik.getFieldProps('ProjectId')}
                  onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                />
                {formik.touched.ProjectId && formik.errors.ProjectId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ProjectId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.INVOICEKINDNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={InvoiceKind}
                  required
                  {...formik.getFieldProps('InvoiceKindId')}
                  onChange={item => formik.setFieldValue('InvoiceKindId', Number(item?.value))}
                />
                {formik.touched.InvoiceKind && formik.errors.InvoiceKind && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.InvoiceKind}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATESUNDRY.EDITDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    {...formik.getFieldProps('EditDate')}
                    onChange={([date]) => formik.setFieldValue('EditDate', date)}
                  />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATESUNDRY.DEFERMENTDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    {...formik.getFieldProps('DefermentDate')}
                    onChange={([date]) => formik.setFieldValue('DefermentDate', date)}
                  />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATESUNDRY.TOTALPRICE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                {/* <input
                  type='number'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('TotalPrice')}
                  onFocus={event => event.target.select()}
                /> */}

                <InputPrice 
                  {...formik.getFieldProps('TotalPrice')} 
                  onChange={(value) => formik.setFieldValue('TotalPrice', Number(value))} 
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATESUNDRY.EXPENSEPAYMENTSTATUS' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={ExpensePaymentStatus} 
                  {...formik.getFieldProps('ExpensePaymentStatusId')}
                  onChange={item => {
                    formik.setFieldValue('ExpensePaymentStatusId', Number(item?.value))
                    setExpensePaymentStatus(Number(item.value))
                  }}
                />
              </div>
            </div>
            {expensePaymentStatus == '2' && <> <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATESUNDRY.ACCOUNT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={accountLookup} 
                  {...formik.getFieldProps('FromAccountId')}
                  onChange={item => formik.setFieldValue('FromAccountId', Number(item?.value))}
                />
              </div>
            </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATESUNDRY.CHECKOUTNAME' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('CheckoutName')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATESUNDRY.PAYMENTDATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish }}
                      {...formik.getFieldProps('PaymentDate')}
                      onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                    />
                  </div>
                </div>
              </div></>}
            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
